/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "us-east-1",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_UZMwRAofN",
  aws_user_pools_web_client_id: "78pmhqekdghld57imqc1fhrbol",
  oauth: {
    domain: "dashboard25702ff7-25702ff7-dev.auth.us-east-1.amazoncognito.com",
    scope: [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin",
    ],
    redirectSignIn:
      "http://localhost:3002/,https://dashboard.dev.axle.insure/,https://dashboard.sandbox.axle.insure/,https://dashboard.axle.insure/",
    redirectSignOut:
      "http://localhost:3002/,https://dashboard.dev.axle.insure/,https://dashboard.sandbox.axle.insure/,https://dashboard.axle.insure/",
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
  aws_cognito_username_attributes: ["EMAIL", "PHONE_NUMBER"],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ["EMAIL", "NAME"],
  aws_cognito_mfa_configuration: "ON",
  aws_cognito_mfa_types: ["TOTP"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
  aws_cloud_logic_custom: [
    {
      name: "AdminQueries",
      endpoint: "https://3wccq3447b.execute-api.us-east-1.amazonaws.com/dev",
      region: "us-east-1",
    },
  ],
};

export default awsmobile;
