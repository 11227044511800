import _ from "lodash";

const currency = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
});

export const formatAsCurrency = (value) => {
  return currency.format(value);
};

/**
 * Sleep() returns a promise that resolves after a given number of milliseconds.
 * @param ms - The number of milliseconds to wait before resolving the promise.
 * @returns A promise that will resolve after the given amount of time.
 */
export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const camelCaseToSentenceCase = (string) => {
  return _(string)
    .chain()
    .replace(/([A-Z])/g, " $1")
    .toLower()
    .upperFirst()
    .value();
};

/**
 * It takes an array of strings and a separator, and returns a string that is the concatenation of the
 * strings in the array, separated by the separator with undefined values filtered out.
 * @param strings - an array of strings
 * @param separator - The separator to use when joining the strings. Defaults to a space.
 * @returns A function that takes two arguments, strings and separator, and returns a string.
 */
export const concatStrings = (strings, separator) => {
  return _(strings)
    .chain()
    .filter((i) => i)
    .join(separator ?? " ")
    .value();
};

export const isSandboxSubdomain = () => {
  if (typeof window === "undefined") return false; // Ensure it's running in a browser environment

  const hostname = window.location.hostname; // Get the current hostname
  const subdomains = hostname.split("."); // Split by dots

  return subdomains.some((sub) => sub.endsWith("sandbox"));
};

export const toggleSandboxSubdomain = () => {
  if (typeof window === "undefined") return; // Ensure it's running in a browser

  const { hostname, protocol, pathname, search, port } = window.location;

  let newHostname = hostname;

  if (hostname.includes("sandbox")) {
    // If 'sandbox' exists, remove it
    newHostname = hostname.replace(".sandbox", "").replace("sandbox.", "");
  } else {
    // If it's localhost, switch to sandbox.localhost
    if (hostname === "localhost") {
      newHostname = "sandbox.localhost";
    } else {
      // Otherwise, insert 'sandbox' after the first subdomain
      const parts = hostname.split(".");
      parts.splice(1, 0, "sandbox");
      newHostname = parts.join(".");
    }
  }

  // Preserve the port if it exists
  const portPart = port ? `:${port}` : "";
  const newUrl = `${protocol}//${newHostname}${portPart}${pathname}${search}`;
  window.location.href = newUrl; // Redirect to the new URL
};
