import { useAuthenticator } from "@aws-amplify/ui-react";
import { Menu, Transition, Switch } from "@headlessui/react";
import React, { useEffect, useRef, useState } from "react";
import { isSandboxSubdomain, toggleSandboxSubdomain } from "../lib/utility";

const Topbar = () => {
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const anchor = useRef();
  const [isSandbox, setIsSandbox] = useState(false);

  const name = user.attributes.name.split(" ");

  useEffect(() => {
    setIsSandbox(isSandboxSubdomain());
  }, []);

  return (
    <div className="flex flex-row justify-end py-6 px-12 gap-8">
      <div className="flex flex-row gap-x-2 items-center cursor-pointer">
        <Switch
          checked={isSandbox}
          onChange={toggleSandboxSubdomain}
          className={`${
            isSandbox ? "bg-green-300" : "bg-gray-300"
          } relative inline-flex h-5 w-9 items-center rounded-full`}
        >
          <span
            className={`${
              isSandbox ? "translate-x-[1.125rem]" : "translate-x-0.5"
            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
          />
        </Switch>
        <p className="text-gray-500 text-sm font-medium">Sandbox mode</p>
      </div>
      <Menu as="div" className={"relative"}>
        <Menu.Button
          as="div"
          className={"flex flex-row gap-x-2 items-center cursor-pointer"}
        >
          <div className="rounded-full h-7 w-7 bg-gray-300 bg-5/8 bg-no-repeat bg-cover bg-center flex justify-center items-center text-sm font-medium text-white">
            {name[0].substring(0, 1)}
            {name[1].substring(0, 1)}
          </div>
          <div ref={anchor} className="relative">
            <p className="text-gray-500 text-sm font-medium">
              {user.attributes.name}
            </p>
          </div>
        </Menu.Button>
        <Transition
          as={"Fragment"}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute bg-white transform translate-y-2 w-full rounded-md shadow-lg focus:outline-none text-gray-400">
            <div className="flex flex-col gap-y-2 p-4">
              <Menu.Item
                as="div"
                className={"cursor-pointer text-sm hover:text-black"}
                onClick={signOut}
              >
                Sign Out
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default Topbar;
